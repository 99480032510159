@tailwind base;
@tailwind components;
@tailwind utilities;

/* 自定义样式 */
html, body, #root {
  height: 100%;
}

.bg-soft-header {
  @apply bg-gray-50;
}

.bg-soft-main {
  @apply bg-gray-100;
}

.bg-soft-footer {
  @apply bg-gray-50;
}

.header-link {
  @apply text-sm hover:text-gray-700 block py-2 px-4;
}

.header-button {
  @apply py-2 px-4 rounded;
}

.header-login-button {
  @apply bg-gray-300 hover:bg-gray-400;
}

.header-register-button {
  @apply bg-blue-500 hover:bg-blue-600 text-white;
}