.page {
  box-sizing: border-box;
  min-height: 100%;
  /* padding-bottom: 80px; */
  /* margin-top: 81px; */
}

.paydemo .content {
  max-width: 1120px;
  margin: 0 auto;
}

.paydemo .paydemo-type-content {
  padding: 20px;
  margin-bottom: 10px;
  background-color: #FFFFFF;
  border-radius: 6px;
}

.paydemo .paydemo-type-content p {
  margin-bottom: 10px;
  text-align: left;
}

.paydemo .colorChange {
  color: #1953ff;
  border: solid 2px #1953ff;
  padding: 12px;
  margin-right: 10px;
  cursor: pointer;
}

.paydemo .paydemoType {
  padding: 12px;
  border: solid 2px #e2e2e2;
  margin-right: 10px;
  cursor: pointer;
}

.paydemo .paydemo-type-img {
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.paydemo .paydemo-type-name {
  font-size: 16px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.paydemo .paydemo-type-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.paydemo .paydemo-type-h5 {
  padding: 12px;
  border: solid 2px #e2e2e2;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
}

.paydemo .codeImg_wx_h5 {
  position: absolute;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  width: 160px;
  margin-bottom: 20px; /* 增加下方间距 */
}

.paydemo .codeImg_wx_h5 span {
  margin-top: 10px; /* 增加二维码与文字之间的间距 */
}

.paydemo .paydemo-form-item {
  height: 38px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

img {
  border: 0;
  vertical-align: middle;
}