/* 页面布局样式 */
.page.paydemo {
    max-width: 1120px;
    margin: 0 auto;
    padding-top: 30px;
}

.page {
    margin-top: 50px;
    padding: 20px;
    background: #f5f5f7;
}

/* 主导航容器样式 */
.mainnav-container {
    background: #000000;
    height: 50px;
}

/* 支付体验页面主要内容区域 */
.paydemo .content {
    max-width: 1120px;
    margin: 0 auto;
}

/* 支付方式内容容器 */
.paydemo-type-content {
    padding: 20px;
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 6px;
}

/* 支付方式选择卡片 */
.paydemo-type {
    padding: 8px;
    /* 将 padding 调整为 8px，减少内部空白 */
    border: 1px solid #e2e2e2; /* 边框设置为 1px */
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    /* 使得内容居中对齐 */
    align-items: center;
    /* 垂直居中对齐 */
    transition: all 0.3s ease;
    /* 增加平滑过渡效果 */
}

/* CashierTestPage2.css */
.paydemo-type-selected {
    border: 1px solid #1890ff; /* 使用 Ant Design 的默认蓝色高亮 */
    box-shadow: 0 0 10px rgba(24, 144, 255, 0.5);
}

.paydemo-type:hover {
    border-color: #1953ff;
    /* 增加 hover 效果 */
}

/* 支付方式图片图标 */
.paydemo-type-img,
.paydemo .paydemo-type img {
    width: 36px;
    /* 调整图标大小 */
    height: 36px;
    margin-right: 8px;
    /* 减少图标与文字之间的间距 */
    vertical-align: middle;
    /* 修正垂直对齐 */
}

/* 支付方式选中样式 */
.paydemo .this {
    color: #1953ff;
    border-color: #1953ff;
}

/* 支付方式名称 */
.paydemo .paydemo-type-name,
.paydemo .paydemo-type .ant-card-meta-title {
    font-size: 14px;
    /* 调整字体大小 */
    font-weight: normal;
    /* 设置字体为正常（非加粗） */
    margin-bottom: 0;
    /* 去除额外的 margin */
}

.paydemo .paydemo-type:hover .ant-card-meta-title {
    color: #1953ff;
    /* Hover 时字体颜色 */
}

/* 卡片内部结构样式 */
.paydemo .paydemo-type .ant-card-meta,
.paydemo .paydemo-type .ant-card-body {
    margin: 0;
    padding: 0;
    /* 去除卡片内部的默认 margin 和 padding */
}

/* 表单和支付信息项样式 */
.paydemo .layui-input {
    width: 50%;
    display: inline;
    font-size: 14px;
}

.paydemo .paydemo-form-item {
    height: 38px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* 单选框样式 */
.paydemo .layui-form-radio i:hover,
.paydemo .layui-form-radioed i {
    color: #1953ff;
}

/* 分页组件样式 */
.paydemo .layui-laypage .layui-laypage-curr .layui-laypage-em,
.paydemo .layui-laypage a:hover {
    background-color: #1953ff;
    color: #1953ff;
}

/* 按钮样式 */
.paydemo .paydemo-btn {
    border: 1px solid #e2e2e2;
    background-color: #ffffff;
    color: #000000;
    margin-left: 8px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

/* 弹窗图片样式 */
.paydemo .codeImg_wx_h5 {
    position: absolute;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    width: 160px;
}

/* 扫码区域样式 */
.paydemo .paydemo-scan {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* 分页组件样式 */
.paydemo .layui-anim.layui-icon {
    margin-top: 3px;
}

.paydemo .layui-form-radio {
    margin-top: 2px;
}

/* 文本样式 */
.paydemo-type-content p {
    margin-bottom: 10px;
}

/* 支付信息表格样式 */
.layui-table-view .layui-table {
    width: 100%;
}

.layui-table-view .layui-table tr:hover {
    background-color: #f5f5f5;
}

/* 其他页面通用样式 */
.alert {
    margin-bottom: 20px;
}
