/* 全局设置 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* 页面容器样式 */
.login-page {
  position: relative;
  width: 100%;
  height: 100vh; /* 确保页面占满整个视口高度 */
  display: flex;
  align-items: center;       /* 垂直居中 */
  justify-content: flex-end; /* 水平靠右 */
  background-color: #f0f2f5;
  padding-right: 250px;      /* 控制与右边缘的距离 */
}

/* 背景图片样式 */
.login-background {
  position: absolute;
  inset: 0;                  /* 等同于 top: 0; right: 0; bottom: 0; left: 0; */
  overflow: hidden;          /* 确保子元素不会溢出 */
}

/* 使用伪元素添加模糊的背景图片 */
.login-background::before {
  content: "";
  position: absolute;
  inset: -30px;              /* 扩大范围，防止模糊后边缘出现空白 */
  background: url('./th.webp') center/cover no-repeat;
}

/* 添加半透明遮罩层，降低视觉干扰 */
.login-background::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.1); /* 黑色半透明，透明度可调整 */
}

/* 登录容器样式 */
.login-container {
  width: 100%;
  max-width: 400px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;       /* 已经垂直居中，无需 justify-content */
  z-index: 1;
}

/* 登录卡片样式 */
.login-card {
  width: 100%;
  padding: 24px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* 登录按钮样式 */
.login-form-button {
  background-color: #1890ff;
  border-color: #1890ff;
}

/* 忘记密码链接样式 */
.login-form-forgot {
  font-size: 14px;
  color: #1890ff;
  cursor: pointer;
}

.login-form-forgot:hover {
  text-decoration: underline;
}

/* 页脚样式 */
.login-footer {
  position: fixed;           /* 固定定位 */
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Helvetica,
    "Microsoft YaHei", "PingFang SC", "Hiragino Sans GB", SimSun, sans-serif;
  font-size: 14px;
  color: rgb(179, 179, 179);
  line-height: 22px;
  letter-spacing: 1.5px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.login-footer p {
  margin: 0;
  line-height: 1.5;
}

.login-footer a {
  color: inherit;            /* 使用继承的文字颜色 */
  cursor: pointer;
  text-decoration: none;
}

.login-footer a:hover {
  text-decoration: underline;
}

/* ICP 图标样式 */
.icp-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 16px;
  height: auto;
}

/* 响应式布局 */
@media (max-width: 768px) {
  .login-page {
    justify-content: center; /* 小屏幕下居中显示 */
    padding-right: 0;
  }
}